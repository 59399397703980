document.addEventListener("turbolinks:load", function() {
  videoBtns = document.querySelectorAll('.js-usage-video');
  if(videoBtns != null) {
    videoBtns.forEach(el => {
      el.addEventListener('click', function(){
        div11 = el;
        if(!div11.classList.contains('js-main-video')){
          div11 = div11.closest('.js-main-video');
        }
        var targetModal = $(div11).data('target');
        $(targetModal)[0].style.zIndex = 1056;
        $(targetModal).modal('show');

        // play video
        $(targetModal)[0].querySelector('#modalVideo').play();
        console.log('playing video');
      });
    });
  }
  videoModals = document.querySelectorAll('.js-video-modal');
  if(videoModals != null){
    videoModals.forEach(vd => {
      // on modal close video must stop
      vd.addEventListener('hidden.bs.modal', function(){
        vd.querySelector('#modalVideo').pause();
        console.log('puased video');
      });
    });
  }
});

// Service Agreement Plans
document.addEventListener("turbolinks:load", function() {
  // Service Type page Next Btn
  var serviceSelctBtns2 = document.querySelectorAll(".slct-btn-quote");
  serviceSelctBtns2.forEach((selectBox) => {
    selectBox.addEventListener("click", function(event) {
      card = this.closest('.card');
      mainPlanDiv = this.closest('.service-plans-div');
      if(mainPlanDiv != null){
        nextBtn2 = mainPlanDiv.querySelector('.js-service-next-btn');
        if(card.id == "no-agreement"){
          if(this.checked == false){
            nextBtn2.classList.add("disabled");
            card.classList.remove("active-usage-card");
            return;
          }
        }
        otherCards = mainPlanDiv.querySelectorAll('.js-slct-card');
        if(!card.classList.contains('active-usage-card')) {
          otherCards.forEach((tcard) => {
            //tcard.classList.remove("active");
            tcard.classList.remove("active-usage-card");
            if(tcard.id == "no-agreement"){
              noAgreeChkBox = tcard.querySelector('.slct-btn-quote');
              if(noAgreeChkBox != null){
                noAgreeChkBox.checked = false;
              }
            }
          });
          //card.classList.add("active");
          card.classList.add("active-usage-card");
          if(card.id == "no-agreement"){
            noAgreeChkBox = card.querySelector('.slct-btn-quote');
            if(noAgreeChkBox != null){
              noAgreeChkBox.checked = true;
            }
          }
          if(nextBtn2 != null){
            nextBtn2.classList.remove("disabled");
          }
          this.closest('form').querySelector('#order_service_agreement').value = this.id;
          // this.closest('form').elements['order_service_agreement'].value = this.id;
          this.closest('form').elements['order_service_agreement'].forEach( el2 =>{
            el2.value = this.id;
          });
          //mainPlanDiv.querySelector('#order_service_agreement').value = this.id;
        }
      }
    });
  });
});

// remote form submission for search
$(document).on("submit", ".js-remote-form", function(event) {
    event.preventDefault(); // Prevent default form submission
    var form = $(this);
    
    $.ajax({
      url: form.attr("action"),
      type: form.attr("method"),
      data: form.serialize(),
      dataType: "script"
    });
  });

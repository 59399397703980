window.noteDelBtnResponse = function(el, mainWindow) {
  $(document).on('ajax:success', el, function(event) {
    var data = event.detail[0]; // Get the response data
    console.log(data); // Log the response data
    if(data.success){
      if(mainWindow){
        window.location.reload();
        return;
      }
      loadNotifictions(); // Reload new notifications
    }
  });
}

window.showNoteToast = function(note) {
  // send notification received
  sendReadNotificationResponse(note.id);

  toastDiv = document.querySelector('.notification-toast-div');
  isAlready = toastDiv.querySelector(`#toast-${note.id}`);
  if(isAlready != null){
    return;
  }
  els = toastDiv.querySelectorAll('.toast');
  if(els != null){
    els.forEach((el) => {
      //el.classList.remove("show");
      el.remove();
    });
  }


  message = note.body;
  toastItemDiv = document.createElement('div');
  toastItemDiv.id = `toast-${note.id}`;
  toastItemDiv.classList.add('toast', 'rounded-3', 'show', 'me-3', 'p-2', 'align-items-center', 'justify-content-start');
  toastItemDiv.innerHTML = message;
  toastDiv.appendChild(toastItemDiv);
  setTimeout(function(){
    toastItemDiv.classList.remove("show");
  }, 5000); // 5000ms = 5 seconds before hiding
  if(note.notify_type == "chats" ){
    loadUnreadCountForEach();
  }
}

function sendReadNotificationResponse(notification_id){
  // the notification is recieved
  note_url = '/update_recieved_notification';
  $.ajax({
    url: note_url,
    type: 'Post',
    data: {notification_id: notification_id, authenticity_token: $('meta[name="csrf-token"]').attr('content')},
    dataType: 'json',
    success: function() {
      console.log('success');
    },
    error: function(xhr, status, error) {
      console.error(error);
    }
  });
}

loadNotifictions = () => {
  note_url = '/user_notifications';
  noteTxt = $('#notifications-modal')[0].querySelector('.notes-div');
  if(noteTxt.innerHTML == ''){
    $('#notifications-modal')[0].querySelector('.notes-div').innerHTML =
        ` <div class="d-flex flex-column py-2">
            <span class="spinner-border spinner-border-sm js-loader" role="status" aria-hidden="true"></span>
          </div>`;
  }
  $.ajax({
    url: note_url,
    type: 'GET',
    data: {authenticity_token: $('meta[name="csrf-token"]').attr('content')},
    dataType: 'script', // This specifies that you expect JavaScript in response
    success: function() {
      // You can update the page or do something with the response here
      // For example, update the content in a div with id "filtered-products"
      console.log('success');
    },
    error: function(xhr, status, error) {
      console.error(error);
    }
  });
}

document.addEventListener('turbolinks:load', () => {
  // Add an event listener to the notification modal show event
  var noteModal = document.getElementById('notifications-modal');
  if(noteModal != null) {
    noteModal.addEventListener('show.bs.modal', loadNotifictions);
  }
});

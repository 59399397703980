document.addEventListener("turbolinks:load", function() {
  window.showProductAlert = function(event) {
    const overlay = $("<div>", { id: "overlay", class: "modal-backdrop" });
    const spinnerContainer = $("<div>", { class: "spinner-container text-center" });
    const spinner = $("<div>", { class: "spinner-border", role: "status" });
    const savingText = $("<div>", { class: "fs-5 text-light fw-semibold" }).text("Uploading...");
    spinnerContainer.append(spinner, savingText);
    overlay.append(spinnerContainer);
    $(".inventory-card-parent").prepend(overlay);
    overlay.fadeIn();

    return false; // Prevent the form submission
  };
});

import consumer from "./consumer"

let currentMSgSubscription = null;
let totalUnreadCount = null;
const subscribeToMessages = () => {
  var notificationCountDiv = document.getElementById('user-notification-count');
  if(notificationCountDiv != null && !currentMSgSubscription){
    var objctId = notificationCountDiv.dataset.obj;
    var data1 = {"objct_id": Number(objctId)};
    currentMSgSubscription = consumer.subscriptions.create({ channel: "MessageChannel", data: JSON.stringify(data1) }, {
      connected(){
        this.perform('subscribe');
        console.log("Connected to Message channel.");
        // loadUnreadCountForEach();
      },
      received(data) {
        console.log(data);
        if(data['action'] == "init_message_count"){
          loadUnreadCountForEach();
        }
      }
    });
  }
}

window.loadUnreadCountForEach = () => {
  var allUserUnReadMsgs = 0;
  var totalConvocount = 0;
  var channelListeners = {};
  var url = window.location.pathname.includes('dealers') ? '/dealers/chats/token' : '/chats/token';
  let totalUnread = 0;
  totalConvocount = 0;
  // convoDivs = document.querySelector('#messages-modal').querySelectorAll(".convo-div");
  // if( convoDivs != null ){
  //   if(convoDivs.length > 1){
  //     totalConvocount = convoDivs.length;
  //   }
  // }
  if(window.conversationClient){
    $.getJSON(url, function(data) {
      window.conversationClient.getSubscribedConversations().then((paginator) => {
        console.log('Total unread messages count start from:', totalUnread);
        paginator.items.forEach((channel) => {
          const channelId = channel.sid;
          channel.removeAllListeners();
          //setupMessageAddedListener(channel);
          channelListeners[channelId] = true;
          channel.getMessagesCount().then( (messagesCount) => {
            totalUnread = channel.lastReadMessageIndex === null ? messagesCount : messagesCount - channel.lastReadMessageIndex;
            console.log(totalUnread);
            if(totalUnread && totalUnread > 0){
              allUserUnReadMsgs += totalUnread;
              setUnreadCount(allUserUnReadMsgs, totalConvocount);
              $(`span[channel_unique_name="channel_unique_name_${channel.uniqueName}"]`).text(totalUnread)
              $(`div[channel_unique_name_indicator="channel_unique_name_${channel.uniqueName}"]`).removeClass('d-none')
            }
          }).catch( error => { console.error(error); })
        });
      }).catch( error => {
        console.error(error);
      });
    });
  }
  else{
    $.getJSON(url, function(data) {
      if(data == null) { return; }

      Twilio.Conversations.Client.create(data.token).then(client => {
        console.log('Created conversation client');
        window.conversationClient = client;

        window.conversationClient.getSubscribedConversations().then((paginator) => {
          let totalUnread = 0;
          console.log('Total unread messages count start from:', totalUnread);

          paginator.items.forEach((channel) => {
            const channelId = channel.sid;
            channel.removeAllListeners();
            //setupMessageAddedListener(channel);
            channelListeners[channelId] = true;
            channel.getMessagesCount().then( (messagesCount) => {
              totalUnread = channel.lastReadMessageIndex === null ? messagesCount : messagesCount - channel.lastReadMessageIndex;
              if(totalUnread && totalUnread > 0){
                allUserUnReadMsgs += totalUnread;
                setUnreadCount(allUserUnReadMsgs, totalConvocount);
                var listItem = $(`#channel_id_${channel.sid}`)
                $(`span[channel_unique_name="channel_unique_name_${channel.uniqueName}"]`).text(totalUnread)
                $(`div[channel_unique_name_indicator="channel_unique_name_${channel.uniqueName}"]`).removeClass('d-none')
              }
            }).catch( error => { console.error(error); })
          });
        }).catch( error => {
          console.error(error);
        });
      }).catch(error => {
        console.error(error);
        console.log('There was an error creating the conversation client:<br/>' + error, true);
        console.log('Please check your .env file.', false);
      });
    });
  }
}
function setUnreadCount(allUserUnReadMsgs, totalConvocount){
  var defaultCount = Number(document.getElementById('user-message-count').dataset.conv);
  var minusCount = defaultCount;
  var count = allUserUnReadMsgs; // - minusCount;
  totalUnreadCount = count;
  document.getElementById('user-message-count').innerHTML = count;
}

const setDefaultCount = () => {
  if(totalUnreadCount != null){
    var countDiv = document.getElementById('user-message-count');
    if(countDiv != null){
      countDiv.innerHTML = totalUnreadCount;
    }
  }
  else{
    loadUnreadCountForEach();
  }
}

export { subscribeToMessages };
export { setDefaultCount };

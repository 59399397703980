// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require jquery
//= require jquery_ujs

// // Make jQuery available everywhere
import jquery from 'jquery';
window.$ = window.jquery = jquery;

//  make bootstrap available
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import "bootstrap"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "./shared"
import "./shared/search"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// Import Select2 CSS
import 'select2/dist/css/select2.css';

// Import Select2 JavaScript
import 'select2';

import flatpickr from 'flatpickr';
import "flatpickr/dist/flatpickr.css";



// Check if user is logged in
const isLoggedIn = document.querySelector('meta[name="user-logged-in"]').content === 'true';
// import messages, notifications and chart module if the user is logged in
if (isLoggedIn) {
  // Messages
  import("./messages");

  // User Messages Channel
  import("../channels/message_channel").then(({ subscribeToMessages, setDefaultCount }) => {
    document.addEventListener('turbolinks:load', () => {
      subscribeToMessages();
      setDefaultCount();
    });
  })

  // User Notifications Channel
  import("../channels/notification_channel").then(({ subscribeToNotifications }) => {
    document.addEventListener('turbolinks:load', () => {
      subscribeToNotifications();
    });
  });

  // For Charts
  import("chartkick/chart.js");

  // action text
  import("trix");
  import("@rails/actiontext");

  // stripe
  import("./stripe");
}

// preview image js to show uploaded image in the brand logo section

document.addEventListener('turbolinks:load', function() {
  const logoInput = document.querySelector('input[type=file]');
  const logoPreview = document.querySelector('.js-image-preview');

  if(logoInput != null ){
    logoInput.addEventListener('change', function () {
      const file = logoInput.files[0];
      const url = URL.createObjectURL(file);
      logoPreview.src = url;
    });
  }
});

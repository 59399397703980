window.editMachineTrackingInfo = function(el){
  machineForm = el.closest('form');
  if(machineForm != null){
    inptEls = machineForm.querySelectorAll('.js-machine-loc-input');
    if(inptEls != null){
      inptEls.forEach( impEl => {
        console.log(impEl);
        if(impEl.getAttribute('disabled') == 'disabled'){
          impEl.removeAttribute('disabled');
          impEl.classList.add('border');
          impEl.classList.remove('border-0');
          impEl.classList.add('p-1');
        }else{
          impEl.setAttribute('disabled', 'disabled');
          impEl.classList.remove('border');
          impEl.classList.add('border-0');
          impEl.classList.remove('p-1');
        }
      });
    }
    inpElBtn = machineForm.querySelector('.js-machine-loc-btn');
    if(inpElBtn != null) {
      if(inpElBtn.classList.contains('d-none')){
        inpElBtn.classList.remove('d-none');
      }else{
        inpElBtn.classList.add('d-none');
      }
    }
  }
}

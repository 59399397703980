import consumer from "./consumer"

let currentSubscription = null;
const subscribeToNotifications = () => {
  var notificationCount = document.getElementById('user-notification-count');
  if(notificationCount != null && !currentSubscription){
    var objctId = notificationCount.dataset.obj;
    var data1 = {"objct_id": Number(objctId)};
    currentSubscription = consumer.subscriptions.create({ channel: "NotificationChannel", data: JSON.stringify(data1) }, {
      connected(){
        this.perform('subscribe');
        console.log("Connected to Notification channel.");
      },
      received(data) {
        console.log(data);
        var noteModalParrent = notificationCount.closest('.js-note-modal-parent');
        var noteModal = noteModalParrent.querySelector('#notifications-modal');
        if(noteModal == null){
          noteModal = document.querySelector('.js-customer-header-modals').querySelector('#notifications-modal');
        }
        if(data['action'] == "unread_notifications_count"){
          if(data.notifications_count > 0){
            notificationCount.textContent = data.notifications_count;
          }
          else{
            notificationCount.textContent = 0;
          }
        }
        else if(data['action'] == "last_unread_notification"){
          console.log(data.notifications);
          // Notification Modal is visible the fetch notifications
          if(noteModal.classList.contains('show')){
            loadNotifictions();
          }
          // Show notifiction toast

          showNoteToast(data.notifications);
        }
        else if(data['action'] == "last_new_notification"){
          console.log(data.notifications);
          // Notification Modal is visible the fetch notifications
          if(noteModal.classList.contains('show')){
            loadNotifictions();
          }
          // Show notifiction toast

          // showNoteToast(data.notifications);
          data.notifications.forEach((note) => {
            showNoteToast(note);
          });
        }
        else if(data['action'] == 'last_unread_message'){
          console.log(data.notifications);
          showNoteToast(data.notifications);
          getUnreadCountForEach();
        }
      }
    });
  }
}
export { subscribeToNotifications };

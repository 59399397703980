// This file is used to allow dealer to select Multiple options
// while dealer tried to register.

$(document).on('turbolinks:load', function() {
  $('.brand-image').off('click').on('click', function() {
    var brandId = $(this).data('brandId');
    var brandIdsDiv = $('#brand_ids_fields');

    $(this).toggleClass('selected');

    if ($(this).hasClass('selected')) {
      // If the brand was selected, add a hidden field for its ID
      brandIdsDiv.append('<input type="hidden" name="user[brand_ids][]" value="' + brandId + '">');
    } else {
      // If the brand was deselected, remove the hidden field for its ID
      brandIdsDiv.find('input[value="' + brandId + '"]').remove();
    }
  });

  // Add already selected brand ids to the form as hidden fields
  $('.brand-image.selected').each(function() {
    var brandId = $(this).data('brandId');
    $('#brand_ids_fields').append('<input type="hidden" name="user[brand_ids][]" value="' + brandId + '">');
  });
});

// it will allow user to select image from gallery
// when user clicks o edit button and create image for user

document.addEventListener('turbolinks:load', function() {
  $('.js-select-picture-btn').on('click', function() {
      var inputId = $(this).data('input-id');
      $('#' + inputId).click();
  });

  $('.js-picture-input').on('change', function() {
    var file = $(this).prop('files')[0];
    if (file) {
      var fileType = file.type.startsWith('image/') ? 'image' : (file.type.startsWith('video/') ? 'video' : 'file');
      var url = URL.createObjectURL(file);
      $('.js-selected-picture').attr('src', url);
      var formData = new FormData();
      formData.append(this.id, file);
      formData.append('authenticity_token', $('meta[name="csrf-token"]').attr('content'));

      var overlay = showProductAlert(); // Show the loader

      $.ajax({
        url: '/update_media',
        type: 'PATCH',
        data: formData,
        contentType: false,
        processData: false,
        success: function(response) {
          alert(fileType + ' uploaded successfully');
          location.reload();
        },
        error: function(xhr, status, error) {
          alert('error uploading ' + fileType);
        }
      });
    }
  });
});

window.orderBtn = function(el){
  console.log(el);
  var dBox = el.closest('.d-box');
  if(dBox != null){
    if( el.getAttribute('data-target').split('-')[2] != 'purchase' ){
      if(!checkServicePlan(el)){
        markServiceBtn(dBox);
        alert('Please select a service plan to continue!');
        return;
      }
    }
    removeMarkServiceBtn(el);
  }
  var modalId = $(el).data('target');
  var targetModal = el.closest('form').querySelector(modalId);
  // onshow event for modal
  targetModal.addEventListener('show.bs.modal', function(){
    onShowModal(targetModal);
  });
  // onhide event for modal
  targetModal.addEventListener('hidden.bs.modal', function(){
    onHideModal(targetModal);
  });
  el.closest('.p-box').classList.add('active-btn');
  $(targetModal).modal('show');
}

function markServiceBtn(dBox){
  var srvBtn = dBox.querySelector('.js-select-plan');
  if(srvBtn != null){
    srvBtn.classList.add('blinking-border-1');

    // will remove the blinking highlight after 8 seconds
    setTimeout(() => {
      srvBtn.classList.remove('blinking-border-1');
    }, 80000); // 80000 milliseconds
  }
}

window.removeServiceBtnFlashing = function(srvBtn){
  if(srvBtn != null){
    srvBtn.classList.remove('blinking-border-1');
  }
}

function removeMarkServiceBtn(el){
  var dBox = el.closest('.d-box');
  if(dBox != null){
    var srvBtn = dBox.querySelector('.js-select-plan');
    if(srvBtn != null){
      srvBtn.classList.remove('blinking-border-1');
    }
  }
}

function checkServicePlan(el){
  var thisForm = el.closest('form');
  if(thisForm.querySelector('#order_service_agreement').value == ''){
    return false;
  }
  return true;
}

function onHideModal(targetModal){
  targetModal.closest('.card').querySelectorAll('.p-box').forEach(ele => {
    ele.classList.remove('active-btn');
  });
}

function onShowModal(el){
  console.log(`Modal: ${el.id}`);
  order = el.id.split('-')[2];
  deliveryPrice = Number(el.querySelector('.js-delivery-price').id);
  installPrice = Number(el.querySelector('.js-install-price').id);
  grandTotal = 0;
  eqPrice = Number(el.querySelector(`.js-pinfo-${order}-value`).id);
  switch(order) {
    case 'purchase':
      grandTotal = installPrice + deliveryPrice + eqPrice;
      el.querySelector('.js-grand-total').innerHTML = `$${grandTotal}`;
      break;
    case 'rent':
      grandTotal = installPrice + deliveryPrice + (eqPrice * 2);
      el.querySelector('.js-grand-total').innerHTML = `$${grandTotal}`;
      break;
    case 'lease':
      break;
  }
}

window.showUsageModal = function(el){
  var modalId = $(el).data('target');
  var targetModal = el.closest('.products').querySelector(modalId);
  $(targetModal).modal('hide');
  $(targetModal).modal('show');
}

window.closeBackDrop = function(){
  $('.modal-backdrop')[0].classList.remove('modal-backdrop');
}

// For reloading addons prices when quantity changes
window.reloadAddonPrices = function(plus){
  qtyBox = plus.closest('.qty-box');
  productCard = $(qtyBox).closest('.js-signle-product-card');
  if(productCard != null){
    if(productCard[0] != null){
      productCard = productCard[0];
      productId = productCard.id.split('-')[2];

      addonModal = productCard.querySelector(`#create-order-modal-all-${productId}`);
      if(addonModal != null){
        addons = addonModal.querySelectorAll('.js-addon-slct-main');
        firstAddon = null;
        addons.forEach(adon => {
          if(!adon.classList.contains('subtype-select')){
            if(Number(adon.value) > 0) {
              firstAddon = adon;
            }
          }
        });
        if(firstAddon != null){
          // trigering change event to update prices with addons prices added
          newEvent = new Event('change');
          firstAddon.dispatchEvent(newEvent);
        }
      }
    }
  }
}


window.openAddonModal = function(btn){
  var targetModalAddon = $(btn).data('target');
  targetModalAddon = btn.closest('.js-signle-product-card').querySelector(targetModalAddon);
  // $(targetModal)[0].style.zIndex = 1056;
  $(targetModalAddon).modal('show');
}

document.addEventListener("DOMContentLoaded", function() {
  // Function to handle changes in quantity select
  function updateQuantityDisplay(event) {
    const addonType = event.target.id.split("-").pop();
    const selectedQuantity = event.target.value;
    let targetElement = document.getElementById(event.target.id);

    if (targetElement) {
      targetElement.value = selectedQuantity;
    }
  }

  function updateSubtypeDisplay(event) {
    const addonType = event.target.id.split("-").pop();
    const selectedType = event.target.value;
    let targetElement = document.getElementById(event.target.id);

    if (targetElement) {
      targetElement.value = selectedType;
    }
  }

  document.querySelectorAll('.quantity-select').forEach(function(selectElement) {
    selectElement.addEventListener('change', updateQuantityDisplay);
  });

  document.querySelectorAll('.subtype-select').forEach(function(selectElement) {
    selectElement.addEventListener('change', updateSubtypeDisplay);
  });

  let leaseButton = document.getElementById("submit-lease");
  let purchaseButton = document.getElementById("submit-purchase");

  leaseButton?.addEventListener("click", function(event) {
    event.preventDefault(); // Prevents the default form submission
    document.getElementById("form_lease").submit(); // Submits the 'rent' form
  });

  purchaseButton?.addEventListener("click", function(event) {
    event.preventDefault(); // Prevents the default form submission
    document.getElementById("form_purchase").submit(); // Submits the 'purchase' form
  });
});

// To calculate the service usage cost on entering number of copies
window.clacServiceAgreementCost = function(inputEL, productId){
  var calcServiceCostUrl = "/calc_product_usage_cost";
  var closestModal = inputEL.closest('.modal');
  if(closestModal == null){
    closestModal = inputEL.closest('.service-plan-win');
  }
  var productCard = closestModal.closest('.js-signle-product-card');
  var bwInput = closestModal.querySelector('.js-bw-copies-input').value;
  var colorInput = closestModal.querySelector('.js-color-copies-input').value;

  var modalBw = closestModal.querySelectorAll('.js-modal-bw-number');
  var modalColor = closestModal.querySelectorAll('.js-modal-color-number');
  if(bwInput != '' || colorInput != ''){
    if(modalBw.innerHTML != bwInput || modalColor.innerHTML != colorInput){
      // Set the modal values
      modalBw.forEach(mbw => {
        mbw.innerHTML = `BW: ${bwInput} copies included`;
      });
      modalColor.forEach(mbw => {
        mbw.innerHTML = `Color: ${colorInput} copies included`;;
      });
      // Send an AJAX request to get new value
      if( productId != null){
        fetch(calcServiceCostUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest', // To specify this is an AJAX request
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content // Add Rails CSRF token
          },
          body: JSON.stringify({
            usage: {
              bw_copies: bwInput,
              color_copies: colorInput,
              product_id: productId
            }
          })
        }).then(response => response.text())
        .then(data => {
          if(data != ''){
            var newUsageCost = JSON.parse(data)['usage'];
            productCard.querySelector(`#js-modal-usage-${productId}`).innerHTML = `Monthly: $${newUsageCost}/month`;
            var productsDiv = inputEL.closest('.modal').parentElement;
            var infoModalServicePlanValues =  productsDiv.querySelectorAll('.js-service-agreement-price');
            var selectedPlanId = getSelectedPlanId(inputEL);
            if(infoModalServicePlanValues != null){
              infoModalServicePlanValues.forEach(attr2 => {
                attr2.innerHTML = `$${newUsageCost} /month`;
              });
            }
          }
        })
        .catch(error => console.error('Error:', error));
      }
    }
  }
}

window.orderFlowAddSerivcePlan = function(inputEL){
  var productsDiv = inputEL.closest('.modal').parentElement;
  var bwInput = inputEL.closest('.modal').querySelector('.js-bw-copies-input').value;
  var colorInput = inputEL.closest('.modal').querySelector('.js-color-copies-input').value;
  var productId = inputEL.id;
  var selectedPlanId = getSelectedPlanId(inputEL);
  productsDiv.querySelectorAll(`.js-service-plan-${productId}`).forEach(attr =>{
    attr.value = selectedPlanId;
  });
  productsDiv.querySelectorAll(`.js-color-copies-count-${productId}`).forEach(attr =>{
    attr.value = colorInput;
  });
  productsDiv.querySelectorAll(`.js-bw-copies-count-${productId}`).forEach(attr =>{
    attr.value = bwInput;
  });
  var productLinks = productsDiv.querySelectorAll(`.js-usage-product-link-${productId}`);
  if(productLinks != null){
    productLinks.forEach(attr2 => {
      var currentUrl = attr2.href;
      var tempChar = '?';
      if(currentUrl.includes('?')){
        var tempChar = '&';
      }
      attr2.href = attr2.href + `${tempChar}service_plan=${selectedPlanId}&color_copies=${colorInput}&bw_copies=${bwInput}`;
      if(selectedPlanId == null){
        attr2.href = attr2.id;
      }
      console.log(attr2);
    });
  }
  var infoModalServicePlanValues =  productsDiv.querySelectorAll('.js-service-agreement-price');
  if(infoModalServicePlanValues != null){
    infoModalServicePlanValues.forEach(attr2 => {
      if(selectedPlanId == '1'){
        attr2.innerHTML = `Pay Per Click`;
      }
      else if(selectedPlanId == '0'){
        //
      }
      else{
        attr2.innerHTML = 'Not Included';
      }
    });
  }
  $(inputEL.closest('.modal')).modal('hide');
  updateAddonPrices(inputEL);

  // On product show page update service plan green box
  if(window.location.pathname.includes('/products/')){
    var usageTypes = {0: 'Usage Included', 1: 'Pay Per Click', 2: 'Unlimited', 3: 'No Service Agreement'};
    var servicePlanName1 = document.querySelectorAll('.js-show-product-service-plan-name');
    var bwValue1 = document.querySelectorAll('.js-show-product-bw');
    var colorValue1 = document.querySelectorAll('.js-show-product-color');
    servicePlanName1.forEach(attr =>{
      attr.innerHTML = `${usageTypes[selectedPlanId]}:`;
    });
    if(selectedPlanId != 0){
      bwInput = 0;
      colorInput = 0;
    }
    bwValue1.forEach(attr =>{
      attr.innerHTML = `${bwInput} BW Copies`;
    });
    colorValue1.forEach(attr =>{
      attr.innerHTML = `${colorInput} Color Copies`;
    });
  }
}

function getSelectedPlanId(inputEL){
  var allPckgs = inputEL.form.querySelectorAll('.slct-btn-quote ');
  var selectedPlanId2 = null;
  allPckgs.forEach(pkg =>{
    if(pkg.closest('.js-slct-card').classList.contains('active-usage-card')){
      selectedPlanId2 = pkg.id;
    }
  });
  return selectedPlanId2;
}

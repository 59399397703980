// function will check if payment info already added than show update text
// in button else show add text

$(document).ready(function() {
  var hasValue = false;
  $('form input[type="text"], form input[type="email"]').each(function() {
    if ($(this).val() !== '') {
      hasValue = true;
      return false;
    }
  });

  if (hasValue) {
    $('.js-submit-btn').text('Update');
  }
  else {
    $('.js-submit-btn').text('Add');
  }
});
